<template>
    <div class="Farm">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/Farm/banner.png" alt="">
                <img class="bg2" src="@/assets/Home/Purpleblock.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>Farm</h1>
                    <h2>DEPOSIT LIQUIDITY AND EARN {{tokenSymbol}}</h2>
                </div>
            </div>
        </div>

        <div class="migrationGuide">
            <div class="center connectBoxW">
                <div class="warp">
                    <h1>Farm Migration Guide</h1>
                    <h3>Single Pool: Unstake xSPACE — Migrate xSPACE to xSTAR in 2.0 — Stake xSTAR in 2.0 Farm</h3>
                    <p></p>
                    <h3>LP Pool (with SPACE): Unstake SPACE LP — Remove Liquidity — Migrate SPACE to STAR in 2.0 — Add STAR Liquidity and Stake LP in 2.0 Farm</h3>
                    <p></p>
                    <h3 style="margin-bottom:0;">LP Pool (without SPACE): Unstake LP — Stake LP in 2.0 Farm</h3>
                    <p style="margin-bottom:0;"></p>
                    <a href="https://space-finance.medium.com/spacefi-2-0-product-update-migration-introduction-f551c3ddf4e0" target="_balck">Learn More >></a>
                </div>
            </div>
        </div>
        <div class="blp" id="blpID">
            <div class="center connectBoxW">
                <div class="boostAPR">
                    <div class="warp">
                        <div class="info">
                            <div class="left">
                                <p class="title">Boost APR</p>
                                <p class="text">Add and Stake {{tokenSymbol}} liquidity (bLP) in Farm to boost your APR</p>
                            </div>
                        </div>
                        <div class="info">
                            <div class="left">
                                <p class="smallTitle">Your Boosting Multiplier: {{YourBoostingMultiplier}}</p>
                                <p class="smallText">eg., if the base APR is 150% and  your boosting multiplier is 2, your actual APR=150%*2=300%.</p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div class="btn" @click="farmMessageShow = true;messageIndex = 1">Boost</div>
                </div>
                <div class="bonusForbLP">
                    <div class="warp">
                        <div class="info">
                            <div class="left">
                                <p class="title">Bonus for bLP</p>
                                <p class="text">Claim the Bonus based on Your bLP</p>
                            </div>
                            
                        </div>
                        <div class="info">
                            <div class="left">
                                <p class="smallTitle">Your blp: {{yourBlp}}</p>
                                <p class="smallText">Learn more about the Bonus for <span>bLP, Planet NFT, {{xtokenSymbol}}</span></p>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <router-link tag="div" class="btn" to='/#claim'>
                        Claim
                    </router-link>
                </div>
            </div>
        </div>
        <div class="pools">
            <div class="center connectBoxW">
                <h1 class="centerTItle">All Pools</h1>
                <div class="singlePoolsBox">
                    <ul class="singlePoolsBoxTitles">
                        <li class="singlepoolname">
                            <p>Single Pool</p>
                        </li>
                        <li class="stakedname">
                            <p>Staked</p>
                        </li>
                        <li class="yourstakedname">
                            <p>Your Staked</p>
                        </li>
                        <li class="pendingrewardsname">
                            <p>Pending Rewards</p>
                        </li>
                        <li class="aprname">
                            <p>APR <span>0.00 {{xtokenSymbol}}/day </span></p>
                        </li>
                    </ul>
                    <ul class="singlePoolsBoxList">
                        <li class="poolItem" v-for="(item, index) in farmData.singlePools" :key="index">
                            <div class="ifbox" v-if="item.isShow">
                            <ul :class="item.poolsShow ? 'poolItemUlBg poolItemUlpc' : 'poolItemUlpc poolItemUlpchover'" @click="poolItemInfo(0,index)">
                                <li class="itemSinglepool">
                                    <img :src="item.coin.icon" alt=""> {{ item.coin.name }}
                                </li>
                                <li class="itemStaked">${{item.allStakedNum.toFixed(0)}}</li>
                                <li class="itemYourstaked">${{item.yourAllStakedNum.toFixed(6)}}</li>
                                <li class="itemPendingrewards" style="line-height: 20px;">
                                    {{item.PendingRewardsstar != 0 ? item.PendingRewardsstar.toFixed(6)+' '+tokenSymbol : '' }}
                                    <br v-if="item.PendingRewardsstar != 0" />
                                    {{item.PendingRewardsxstar != 0 ? item.PendingRewardsxstar.toFixed(6)+' '+xtokenSymbol : '0.000000 '+xtokenSymbol }}
                                    <br v-if="item.PendingRewardsxstar != 0" />
                                    {{item.PendingRewardsOne != 0 ? item.PendingRewardsOne.toFixed(6)+' '+item.lpSymbol : '' }}
                                    <br v-if="item.PendingRewardsOne != 0" />
                                    {{item.PendingRewardsTwo != 0 ? item.PendingRewardsTwo.toFixed(6)+' '+item.lpTwoSymbol : '' }}
                                    <br v-if="item.PendingRewardsTwo != 0" />
                                    {{item.PendingRewardsThree != 0 ? item.PendingRewardsThree.toFixed(6)+' '+item.lpThrSymbol : '' }}
                                    <br v-if="item.PendingRewardsThree != 0" />
                                </li>
                                <li class="itemAPR">
                                    <span>{{item.tokenApr.toFixed(2)}}%</span>
                                    <i class="el-icon-arrow-right"></i>
                                    <span>{{item.tokenAprMax.toFixed(2)}}%(max)</span>
                                </li>
                                <li class="itemIcon">
                                    <i :class="item.poolsShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                </li>
                            </ul>
                            <ul :class="item.poolsShow ? 'poolItemUlBg poolItemUliphone' : 'poolItemUliphone'" @click="poolItemInfo(0,index)">
                                <li class="itemSinglepool">
                                    <img :src="item.coin.icon" alt=""> {{ item.coin.name }}
                                </li>
                                <li class="itemStaked">
                                    <p>Staked</p>
                                    ${{item.allStakedNum.toFixed(0)}}
                                </li>
                                <li class="itemYourstaked">
                                    <p>Your Staked</p>
                                    ${{item.yourAllStakedNum.toFixed(6)}}
                                </li>
                                <li class="itemPendingrewards">
                                    <p>Pending Rewards</p>
                                    {{item.PendingRewardsstar != 0 ? item.PendingRewardsstar.toFixed(6)+' '+tokenSymbol : '' }}
                                    <br v-if="item.PendingRewardsstar != 0" />
                                    {{item.PendingRewardsxstar != 0 ? item.PendingRewardsxstar.toFixed(6)+' '+xtokenSymbol : '0.000000 '+xtokenSymbol }}
                                    <br v-if="item.PendingRewardsxstar != 0" />
                                    {{item.PendingRewardsOne != 0 ? item.PendingRewardsOne.toFixed(6)+' '+item.lpSymbol : '' }}
                                    <br v-if="item.PendingRewardsOne != 0" />
                                    {{item.PendingRewardsTwo != 0 ? item.PendingRewardsTwo.toFixed(6)+' '+item.lpTwoSymbol : '' }}
                                    <br v-if="item.PendingRewardsTwo != 0" />
                                    {{item.PendingRewardsThree != 0 ? item.PendingRewardsThree.toFixed(6)+' '+item.lpThrSymbol : '' }}
                                    <br v-if="item.PendingRewardsThree != 0" />
                                </li>
                                <li class="itemAPR">
                                    <p>APR</p>
                                    <span>{{item.tokenApr.toFixed(2)}}%</span>
                                    <i class="el-icon-arrow-right"></i>
                                    <span>{{item.tokenAprMax.toFixed(2)}}%(max)</span>
                                </li>
                                <li class="itemIcon">
                                    <i :class="item.poolsShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                </li>
                            </ul>
                            <div v-if="item.poolsShow" class="singlePoolItemInfo">
                                <div class="itemInfo" v-for="(items,indexs) in item.pools" :key="indexs">
                                    <!-- <p class="infoTotal">Total APR :</p>
                                    <p class="infoapr">
                                        <span>15%</span>
                                        <i class="el-icon-arrow-right"></i>
                                        <span>36%(max)</span>
                                    </p> -->
                                    <p class="infoxspaceapr">
                                        <a href="">{{xtokenSymbol}}</a>
                                        : {{items.tokenApr.toFixed(2)}}% <i class="el-icon-arrow-right"></i> {{items.tokenAprMax.toFixed(2)}}%(max)
                                    </p>
                                    <div class="border"></div>
                                    <div class="infoyourapr">
                                        <p> Your APR : {{items.yourtokenApr.toFixed(2)}}%</p>
                                        <p v-if="items.boostBtn && items.stakedNum != 0" @click="harvestAll(item,0)">Boost</p>
                                        <p v-else class="no">Boost</p>
                                    </div>
                                    <div class="infoyoucanboost">
                                        <p>{{ items.boostBtn && items.stakedNum != 0? 'You can Boost' : '' }} </p>
                                    </div>
                                    <!-- <div class="border"></div> -->
                                    <div class="infoxspaceearned">
                                        <div>
                                            <p v-if="items.poolInfo.xRate != 10000"><a href="">{{tokenSymbol}}</a> EARNED</p>
                                            <p v-if="items.poolInfo.xRate != 10000"> {{items.PendingRewardsstar}} </p>
                                            <p v-if="items.poolInfo.xRate != 0"><a href="">{{xtokenSymbol}}</a> EARNED</p>
                                            <p v-if="items.poolInfo.xRate != 0"> {{items.PendingRewardsxstar}} </p>
                                            <p v-if="items.poollpInfo.isExtra"><a href="">{{items.poollpInfo.lpSymbol}}</a> EARNED</p>
                                            <p v-if="items.poollpInfo.isExtra"> {{items.PendingRewardsOne}} </p>
                                            <p v-if="items.poollpInfo.isTwoExtra"><a href="">{{items.poollpInfo.lpTwoSymbol}}</a> EARNED</p>
                                            <p v-if="items.poollpInfo.isTwoExtra"> {{items.PendingRewardsTwo}} </p>
                                            <p v-if="items.poollpInfo.isThrExtra"><a href="">{{items.poollpInfo.lpThrSymbol}}</a> EARNED</p>
                                            <p v-if="items.poollpInfo.isThrExtra"> {{items.PendingRewardsThree}} </p>
                                        </div>
                                        <div :class="items.stakedNum == 0 ? 'harvestallbtn' : 'harvestallbtn bg'" @click="harvestAll(item,0)">
                                            Harvest All
                                        </div>
                                    </div>
                                    <!-- <div class="border"></div> -->
                                    <div v-if="item.enableContract" class="infoxspacestaked">
                                        <div>
                                            <p><a href="">{{item.coin.name}}</a> STAKED</p>
                                            <p>$ {{Number(items.stakedNum).toFixed(3)}}</p>
                                        </div>
                                        <div class="minusandplusandstakebtn">
                                            <div v-if="items.stakedNum != 0" class="minusbtn" @click="minusbtn(item,0)"><i class="el-icon-minus"></i></div>
                                            <div v-if="items.stakedNum != 0" class="plusbtn" @click="stakebtn(item,0)"><i class="el-icon-plus"></i></div>
                                            <div v-if="items.stakedNum == 0" class="stakebtn" @click="stakebtn(item,0)">Stake</div>
                                        </div>
                                    </div>
                                    <div v-if="!item.enableContract" class="infoenablecontract" @click="enableContract(item)">Enable Contract <v-LoadingWarp v-if="item.enableContractLoading" :imgSize="1"></v-LoadingWarp></div>
                                    <div class="infodetailsbtn" @click="infodetailsbtn(0,index,indexs)"> {{ items.detailShow ? 'Hide' : 'Details' }} <i :class="items.detailShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></div>
                                    <div class="border" v-if="items.detailShow"></div>
                                    <div class="infodetailinfo" v-if="items.detailShow">
                                        <p>
                                            <span>Total staked:</span>
                                            <span>${{ items.poolInfo.TotalStaked }}</span>
                                        </p>
                                        <p>
                                            <router-link tag="a" to="/xSTAR">get {{item.coin.name}}>></router-link>
                                            <!-- <a href=""></a> -->
                                            <a :href="'https://explorer.zksync.io/address/'+item.coin.contract" target="_block">View Contract>></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="lpPoolsBox">
                    <ul class="lpPoolsBoxTitles">
                        <li class="lppoolname">
                            <p>LP Pool</p>
                        </li>
                        <li class="liquidityname">
                            <p>Liquidity</p>
                        </li>
                        <li class="yourdepositname">
                            <p>Your Deposit</p>
                        </li>
                        <li class="pendingrewardsname">
                            <p>Pending Rewards</p>
                        </li>
                        <li class="aprname">
                            <p>APR</p>
                        </li>
                    </ul>
                    <ul class="lpPoolsBoxList">
                        <li class="poolItem" v-for="(item, index) in farmData.lpPools" :key="index">
                            <div class="ifbox" v-if="item.isShow">
                            <ul :class="item.poolsShow ? 'poolItemUlBg poolItemUlpc' : 'poolItemUlpc poolItemUlpchover'" @click="poolItemInfo(1,index)">
                                <li class="itemLppool">
                                    <img :src="item.coin.tokenA.icon" alt="">
                                    <img :src="item.coin.tokenB.icon" alt="">
                                    {{ item.coin.name }}
                                </li>
                                <li class="itemStaked">${{item.liquidity.toFixed(0)}}</li>
                                <li class="itemYourstaked">${{item.yourAllStakedNum.toFixed(6)}}</li>
                                <li class="itemPendingrewards">
                                    {{item.PendingRewardsstar != 0 ? item.PendingRewardsstar.toFixed(6)+' '+tokenSymbol : '' }}
                                    <br v-if="item.PendingRewardsstar != 0" />
                                    <br v-if="item.PendingRewardsstar != 0" />
                                    {{item.PendingRewardsxstar != 0 ? item.PendingRewardsxstar.toFixed(6)+' '+xtokenSymbol : '0.000000 '+xtokenSymbol }}
                                    <br v-if="item.PendingRewardsxstar != 0" />
                                    <br v-if="item.PendingRewardsxstar != 0" />
                                    {{item.PendingRewardsOne != 0 ? item.PendingRewardsOne.toFixed(6)+' '+item.lpSymbol : '' }}
                                    <br v-if="item.PendingRewardsOne == 0 && item.coin.name == 'ZK-ETH' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-USDC.e' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-ETH'" />
                                    <br v-if="item.PendingRewardsOne == 0 && item.coin.name == 'ZK-ETH' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-USDC.e' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-ETH'" />
                                    {{item.PendingRewardsOne == 0 && item.coin.name == 'ZK-ETH' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-USDC.e' || item.PendingRewardsOne == 0 && item.coin.name == 'STAR-ETH' ? '0.000000'+' '+item.lpSymbol : '' }}
                                    <br v-if="item.PendingRewardsOne != 0" />
                                    {{item.PendingRewardsTwo != 0 ? item.PendingRewardsTwo.toFixed(6)+' '+item.lpTwoSymbol : '' }}
                                    <br v-if="item.PendingRewardsTwo != 0" />
                                    {{item.PendingRewardsThree != 0 ? item.PendingRewardsThree.toFixed(6)+' '+item.lpThrSymbol : '' }}
                                    <br v-if="item.PendingRewardsThree != 0" />
                                    <p v-if="item.coin.name == 'ZK-ETH' || item.coin.name == 'STAR-USDC.e' || item.coin.name == 'STAR-ETH' " style="font-size: 0.7rem; margin-left: 10px; color:#ff8100;">(+$ZK bonus)</p>
                                </li>
                                <li class="itemAPR">
                                    <span>{{(item.lpFee + item.tokenApr).toFixed(2)}}%</span>
                                    <i class="el-icon-arrow-right"></i>
                                    <span>{{(item.lpFee + item.tokenAprMax).toFixed(2)}}%(max)</span>
                                </li>
                                <li class="itemIcon">
                                    <i :class="item.poolsShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                </li>
                            </ul>
                            <ul :class="item.poolsShow ? 'poolItemUlBg poolItemUliphone' : 'poolItemUliphone'" @click="poolItemInfo(1,index)">
                                <li class="itemLppool">
                                    <img :src="item.coin.tokenA.icon" alt="">
                                    <img :src="item.coin.tokenB.icon" alt="">
                                    {{ item.coin.name }}
                                </li>
                                <li class="itemStaked">
                                    <p>Liquidity</p>
                                    ${{item.liquidity.toFixed(0)}}
                                </li>
                                <li class="itemYourstaked">
                                    <p>Your Deposit</p>
                                    ${{item.yourAllStakedNum.toFixed(6)}}
                                </li>
                                <li class="itemPendingrewards">
                                    <p>Pending Rewards</p>
                                    {{item.PendingRewardsstar != 0 ? item.PendingRewardsstar.toFixed(6)+' '+tokenSymbol : '' }}
                                    <br v-if="item.PendingRewardsstar != 0" />
                                    {{item.PendingRewardsxstar != 0 ? item.PendingRewardsxstar.toFixed(6)+' '+xtokenSymbol : '0.000000 '+xtokenSymbol }}
                                    <br v-if="item.PendingRewardsxstar != 0" />
                                    {{item.PendingRewardsOne != 0 ? item.PendingRewardsOne.toFixed(6)+' '+item.lpSymbol : '' }}
                                    <br v-if="item.PendingRewardsOne != 0" />
                                    {{item.PendingRewardsTwo != 0 ? item.PendingRewardsTwo.toFixed(6)+' '+item.lpTwoSymbol : '' }}
                                    <br v-if="item.PendingRewardsTwo != 0" />
                                    {{item.PendingRewardsThree != 0 ? item.PendingRewardsThree.toFixed(6)+' '+item.lpThrSymbol : '' }}
                                    <br v-if="item.PendingRewardsThree != 0" />
                                </li>
                                <li class="itemAPR">
                                    <p>APR</p>
                                    <span>{{(item.lpFee + item.tokenApr).toFixed(2)}}%</span>
                                    <i class="el-icon-arrow-right"></i>
                                    <span>{{(item.lpFee + item.tokenAprMax).toFixed(2)}}%(max)</span>
                                </li>
                                <li class="itemIcon">
                                    <i :class="item.poolsShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                </li>
                            </ul>
                            <div v-if="item.poolsShow" class="lpPoolItemInfo">
                                <div class="itemInfo" v-for="(items,indexs) in item.pools" :key="indexs">
                                    <p class="infoDaysUnbonding">{{items.daysUnbonding}} Days Unbonding</p>
                                    <p class="infoTotal">Total APR :</p>
                                    <p class="infoapr">
                                        <span>{{(item.lpFee + items.otherTokenApr + items.tokenApr).toFixed(2)}}%</span>
                                        <i class="el-icon-arrow-right"></i>
                                        <span>{{(item.lpFee + items.otherTokenAprMax + items.tokenAprMax).toFixed(2)}}%(max)</span>
                                    </p>
                                    <p class="infoxlpfeeapr">
                                        <span class="hoverBox">
                                            <span class="tip">LP Fee</span>
                                            <span class="tiptext">LP Fee is compounded in your LP and can be received when you remove the liquidity.</span>
                                        </span>
                                        : {{item.lpFee.toFixed(2)}}%
                                        
                                    </p>
                                    <p class="infoxspaceapr" :style="{'marginBottom':(item.coin.name == 'ZK-ETH' || item.coin.name == 'STAR-USDC.e' || item.coin.name == 'STAR-ETH' ? '10px' : '30px')}">
                                        <span class="hoverBox">
                                            <span class="tip">{{xtokenSymbol}}</span>
                                            <span class="tiptext">{{xtokenSymbol}} can be staked to earn more reward or unbonded.</span>
                                        </span>
                                        : {{items.tokenApr.toFixed(2)}}% <i class="el-icon-arrow-right"></i> {{items.tokenAprMax.toFixed(2)}}%(max)
                                    </p>
                                    <p class="infoxspaceapr" v-if="item.coin.name == 'ZK-ETH' || item.coin.name == 'STAR-USDC.e' || item.coin.name == 'STAR-ETH'">
                                        <span class="hoverBox">
                                            <span class="tip" style="text-decoration: none;">{{items.poollpInfo.lpSymbol}}</span>
                                            <!-- <span class="tiptext">{{xtokenSymbol}} can be staked to earn more reward or unbonded.</span> -->
                                        </span>
                                        : {{items.otherTokenApr.toFixed(2)}}% <i class="el-icon-arrow-right"></i> {{items.otherTokenAprMax.toFixed(2)}}%(max)
                                    </p>
                                    <div class="border"></div>
                                    <div class="infoyourapr">
                                        <p> Your APR : {{items.yourtokenApr.toFixed(2)}}%</p>
                                        <p v-if="items.boostBtn && items.stakedNum != 0" @click="harvestAll(item,1,indexs)">Boost</p>
                                        <p v-else class="no">Boost</p>
                                    </div>
                                    <div class="infoyoucanboost">
                                        <p>{{ items.boostBtn && items.stakedNum != 0? 'You can Boost' : '' }} </p>
                                    </div>
                                    <div class="infoxspaceearned">
                                        <div>
                                            <p v-if="items.poolInfo.xRate != 10000"><span>{{tokenSymbol}}</span> EARNED</p>
                                            <p v-if="items.poolInfo.xRate != 10000"> {{items.PendingRewardsstar}} </p>
                                            <p v-if="items.poolInfo.xRate != 0"><span>{{xtokenSymbol}}</span> EARNED</p>
                                            <p v-if="items.poolInfo.xRate != 0"> {{items.PendingRewardsxstar}} </p>
                                            <p v-if="items.poollpInfo.isExtra"><span>{{items.poollpInfo.lpSymbol}}</span> EARNED</p>
                                            <p v-if="items.poollpInfo.isExtra"> {{items.PendingRewardsOne}} </p>
                                            <p v-if="items.poollpInfo.isTwoExtra"><span>{{items.poollpInfo.lpTwoSymbol}}</span> EARNED</p>
                                            <p v-if="items.poollpInfo.isTwoExtra"> {{items.PendingRewardsTwo}} </p>
                                            <p v-if="items.poollpInfo.isThrExtra"><span>{{items.poollpInfo.lpThrSymbol}}</span> EARNED</p>
                                            <p v-if="items.poollpInfo.isThrExtra"> {{items.PendingRewardsThree}} </p>
                                        </div>
                                        <div :class="items.stakedNum == 0 ? 'harvestallbtn' : 'harvestallbtn bg'" @click="harvestAll(item,1,indexs)">
                                            Harvest All
                                        </div>
                                    </div>
                                    <div v-if="item.enableContract" class="infoxspacestaked">
                                        <div>
                                            <p><span>{{item.coin.name}}</span> STAKED</p>
                                            <p>$ {{Number(items.stakedNum).toFixed(3)}}</p>
                                        </div>
                                        <div class="minusandplusandstakebtn">
                                            <div v-if="items.stakedNum != 0" class="minusbtn" @click="minusbtn(item,1,indexs)"><i class="el-icon-minus"></i></div>
                                            <div v-if="items.stakedNum != 0" class="plusbtn" @click="stakebtn(item,1,indexs)"><i class="el-icon-plus"></i></div>
                                            <div v-if="items.stakedNum == 0" class="stakebtn" @click="stakebtn(item,1,indexs)">Stake</div>
                                        </div>
                                    </div>
                                    <div v-if="!item.enableContract" class="infoenablecontract" @click="enableContract(item)">Enable Contract  <v-LoadingWarp v-if="item.enableContractLoading" :imgSize="1"></v-LoadingWarp></div>
                                    <div class="infounbodinglist">
                                        <p v-if="items.redeensList.length == 0" class="notunboding">
                                            <span>Unbonding:</span>
                                            <span>$ 0.000</span>
                                        </p>
                                        <ul v-if="items.redeensList.length != 0 " class="haveunboding">
                                            <div v-if="items.unbondingListShow">
                                                <li v-for="(redeenitem,redeenindex) in items.redeensList" :key="redeenindex">
                                                    <p class="unbondingitemClaim">
                                                        <span>Unbonding:</span>
                                                        <span v-if="redeenitem.endTime < nowTime" @click="redeedClaim(item,items,redeenitem)">Claim <v-LoadingWarp v-if="redeenitem.redeedBtnLoading" :imgSize="1"></v-LoadingWarp></span>
                                                        <span v-if="redeenitem.endTime > nowTime" @click="redeedCancel(item,items,redeenitem)">Cancel <v-LoadingWarp v-if="redeenitem.redeedBtnLoading" :imgSize="1"></v-LoadingWarp></span>
                                                    </p>
                                                    <p class="unbondingitemAmount">
                                                        <span>Amount</span>
                                                        <span>$ {{ redeenitem.amount.toFixed(6) }}</span>
                                                    </p>
                                                    <p class="unbondingitemTimeLeft">
                                                        <span>Time Left</span>
                                                        <span>{{ redeenitem.endTimeText }}</span>
                                                    </p>
                                                </li>
                                            </div>
                                            <div v-if="!items.unbondingListShow">
                                                <li>
                                                    <p class="unbondingitemClaim">
                                                        <span>Unbonding:</span>
                                                        <span v-if="items.redeensList[0].endTime < nowTime" @click="redeedClaim(item,items,items.redeensList[0])">Claim <v-LoadingWarp v-if="items.redeensList[0].redeedBtnLoading" :imgSize="1"></v-LoadingWarp> </span>
                                                        <span v-if="items.redeensList[0].endTime > nowTime" @click="redeedCancel(item,items,items.redeensList[0])">Cancel <v-LoadingWarp v-if="items.redeensList[0].redeedBtnLoading" :imgSize="1"></v-LoadingWarp> </span>
                                                    </p>
                                                    <p class="unbondingitemAmount">
                                                        <span>Amount</span>
                                                        <span>$ {{ items.redeensList[0].amount.toFixed(6) }}</span>
                                                    </p>
                                                    <p class="unbondingitemTimeLeft">
                                                        <span>Time Left</span>
                                                        <span>{{ items.redeensList[0].endTimeText }}</span>
                                                    </p>
                                                </li>
                                            </div>
                                        </ul>
                                        <div v-if="items.unbondingAll != 0 && items.redeensList.length > 1" class="unbodingdetailsbtn" @click="unbodingdetailsbtn(1,index,indexs)"> {{ items.unbondingListShow ? 'Hide' : 'Details' }} <i :class="items.unbondingListShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></div>
                                    </div>
                                    <div class="infodetailsbtn" @click="infodetailsbtn(1,index,indexs)"> {{ items.detailShow ? 'Hide' : 'Details' }} <i :class="items.detailShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></div>
                                    <div class="border" v-if="items.detailShow"></div>
                                    <div class="infodetailinfo" v-if="items.detailShow">
                                        <p>
                                            <span>Total staked:</span>
                                            <span>${{ items.poolInfo.TotalStaked }}</span>
                                        </p>
                                        <p>
                                            <a :href="'https://swap-zksync.spacefi.io/#/add/'+item.coin.tokenA.contract+'/'+item.coin.tokenB.contract" target="_div">get {{item.coin.name}}>></a>
                                            <!-- <a href="" target="_block">get {{item.coin.name}}>></a> -->
                                            <a :href="'https://explorer.zksync.io/address/'+item.coin.contract" target="_block">View Contract>></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="nftpool">
            <div class="center connectBoxW">
                <div class="titleBox">
                    <h1 class="centerTItle">NFT Pools</h1>
                    <p class="aprP">
                        <span class="aprLLL">
                            <span class="apr all">APR: {{nftAPR.toFixed(2)}}% <i class="el-icon-arrow-right"></i> {{maxnftAPR.toFixed(2)}}%(max)&nbsp;&nbsp;</span><span class="apr my">&nbsp;&nbsp;Your APR : {{(nftAPR*YourBoostingMultiplier).toFixed(2)}}%</span>
                        </span>
                        

                        <span class="text">NFT Staking sets up APRs separately from the {{xtokenSymbol}} pool.</span>
                    </p>
                </div>
                <div class="nftlist">
                    <div v-if="nftList.length == 0" class="nodata">
                        <img class="nodataImg" src="../assets/logos/spacefiFFF.png" alt="">
                        <p>No data yet</p>
                    </div>
                    <ul v-if="nftList.length != 0" :class="nftList.length <= 3 ? 'li3' : ''">
                        <li v-for="(item,index) in nftList" :key="index">
                            <div class="nftcard" @click="Details(item)">
                                <div class="nftimg">
                                    <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                        <div slot="placeholder" class="image-slot">
                                            loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                        </div>
                                        <div slot="error" class="image-slot" style="color:#fff;">
                                            <i class="el-icon-picture-outline"></i> Failed to load
                                        </div>
                                    </el-image>
                                </div>
                                <div class="nftinfo">
                                    <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                    <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                    <p class="reward">
                                        Staking Rewards: 
                                        <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                        <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                        <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                        <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                        <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="details" @click="Details(item)">
                                <p>Details</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="farmMessageBox" v-if="farmMessageShow" @click="farmMessageShow = false;comfirmLoading = false">
            <div class="connectBoxW">
                <div class="warp" @click.stop>
                    <div class="yourBoostingMultiplier" v-if="messageIndex == 1">
                        <div class="titlebox">
                            <h1>
                                <p>Your Boosting Multiplier : <span>{{YourBoostingMultiplier}}x</span></p>
                                <i class="el-icon-close" @click="farmMessageShow = false;messageIndex = 0"></i>
                            </h1>
                            <div class="textbox">The Boosting Multiplier is based on 
                                <div>
                                    Your bLP Ratio in the gradient.
                                    <p class="tips">
                                        <span>bLP Ratio Multiplier:</span>
                                        <ul>
                                            <li>0-0.01</li>
                                            <li>0.01-0.05</li>
                                            <li>0.05-0.1</li>
                                            <li>0.1-0.2</li>
                                            <li>0.2-0.3</li>
                                            <li>0.3-0.4</li>
                                            <li>0.4-0.5</li>
                                            <li>0.5-0.6</li>
                                            <li>0.6-0.7</li>
                                            <li>0.7-0.8</li>
                                            <li>0.8-0.9</li>
                                            <li>0.9-1</li>
                                            <li>above 1</li>
                                        </ul>
                                        <ul>
                                            <li>Farm Multiplier 1x</li>
                                            <li>Farm Multiplier 1.1x</li>
                                            <li>Farm Multiplier 1.2x</li>
                                            <li>Farm Multiplier 1.3x</li>
                                            <li>Farm Multiplier 1.4x</li>
                                            <li>Farm Multiplier 1.5x</li>
                                            <li>Farm Multiplier 1.6x</li>
                                            <li>Farm Multiplier 1.7x</li>
                                            <li>Farm Multiplier 1.8x</li>
                                            <li>Farm Multiplier 1.9x</li>
                                            <li>Farm Multiplier 2x</li>
                                            <li>Farm Multiplier 2.1x</li>
                                            <li>Farm Multiplier 2.2x</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="infobox">
                            <div class="smallTitlebox">
                                <h2>Your bLP: <span>{{yourBlp}}</span></h2>
                                <div @click="goAddLiquiditySTAR" >
                                    <p class="getblpbtn">Get bLP</p>
                                </div>
                            </div>
                            <div class="text">
                                Adding and Staking {{tokenSymbol}}-USDC.e/ETH LP in Farm, you'll get a certain amount of bLP. The longer unbonding, the more bLP.
                                <span>
                                    (0 days, 0.1; 7 days, 0.5; 14 days, 1)
                                    <p class="tips">
                                        eg., If you stake 100$ {{tokenSymbol}} LP in 14 Days Unbonding Pool, your bLP=100*1=100; If in 7 Days Unbonding Pool, your bLP=100*0.5=50; If in 0 Days Unbonding Pool, your bLP=100*0.1=10.
                                    </p>
                                </span>
                                . If unstaking {{tokenSymbol}} LP, your bLP will be reduced accordingly. 
                            </div>
                        </div>
                        <div class="infobox">
                            <div class="smallTitlebox">
                                <h2>Your bLP Ratio: <span>{{yourBlpRatio}}</span></h2>
                                <div>
                                    <p class="mintnftbtn" @click="goMint">Mint NFT</p>
                                    <p class="getxspacebtn" @click="goxSPACE">Get {{xtokenSymbol}}</p>
                                </div>
                            </div>
                            <p class="text mb30">
                                Your bLP Ratio = Your bLP / Your Total Deposit in Farm * Price Fluctuation Factor * Your Planet NFT & {{xtokenSymbol}} Multiplier
                            </p>
                            <div class="border"></div>
                            <div class="smalltext">
                                <p class="smalltexttitle">Price Fluctuation Factor:</p>
                                <p class="smalltexttext">The factor defaults to 1, and can be adjusted based on the price fluctuation of the deposit in Farm.</p>
                                <p class="smalltexttitle">Your Planet NFT & {{xtokenSymbol}} Multiplier: {{nftxspaceMultiplier}}x</p>
                                <p class="smalltexttext">
                                    Holding Planet NFT or {{xtokenSymbol}} (except in vesting), you'll get 
                                    <span>
                                        a Multiplier to amplify the bLP Ratio.
                                        <p class="tips">
                                            <span>{{xtokenSymbol}} (+ Planet NFT Asset)   Multiplier:</span>
                                            <ul>
                                                <li>0-1,000, 1x</li>
                                                <li>1,000-5,000, 1.1x</li>
                                                <li>5,000-10,000, 1.15x</li>
                                                <li>10,000-20,000, 1.2x</li>
                                                <li>20,000-50.000, 1.25x</li>
                                                <li>50.000-100.000, 1.3x</li>
                                                <li>100,000-150.000, 1.35x</li>
                                                <li>150,000-200,000, 1.4x</li>
                                                <li>Above 200,000, 1.5x</li>
                                            </ul>
                                        </p>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="stakebox" v-if="messageIndex == 2">
                        <div class="titlebox">
                            <h1>
                                Stake {{ activeItem.coin.name }}
                                <i class="el-icon-close" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0"></i>
                            </h1>
                            <div v-if="activeislp == 1" class="textbox"> 
                                The LP withdrawal doesn't need unbonding period
                            </div>
                        </div>
                        <div class="infobox">
                            <p class="available">{{ activeItemBalance }} {{ activeItem.coin.name }} Available</p>
                            <div class="iptbox">
                                <input type="number" name="" id="" v-model="inputVal" placeholder="Please enter the amount" step="0.0000000000000001" @keydown="changeNum">
                                <div class="maxbtn" @click="inputVal = activeItemBalance">Max</div>
                            </div>
                            <!-- <p class="estimatedgas">Estimated Gas Refund: 75%-95%</p> -->
                            <p v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="tips">You'll get {{expectedblpnum.toFixed(18)}} bLP to boost Farm APR.</p>
                            <p v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="tips">The longer unbonding, the more bLP.  </p>
                            <a v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="learnmore" href="#blpID" @click="farmMessageShow = false;messageIndex = 0">Learn More</a>
                        </div>
                        <!-- <p style="font-size:12px;margin-bottom:20px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> sdfsdfsdfsdf</p> -->
                        <div class="btnbox">
                            <div class="closebtn" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0;inputVal = null">Close</div>
                            <div class="confirmbtn" @click="stakeComfirm">Confirm <v-LoadingWarp style="margin-left: 5px;" v-if="comfirmLoading" :imgSize="1"></v-LoadingWarp></div>
                        </div>
                    </div>
                    <div class="unstakebox" v-if="messageIndex == 3">
                        <div class="titlebox">
                            <h1>
                                Unstake {{ activeItem.coin.name }}
                                <i class="el-icon-close" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0"></i>
                            </h1>
                            <div v-if="activeislp == 1" class="textbox"> 
                                The LP withdrawal doesn't need unbonding period
                            </div>
                        </div>
                        <div class="infobox">
                            <p class="available">{{ activeItemBalance }} {{ activeItem.coin.name }} Available</p>
                            <div class="iptbox">
                                <input type="number" name="" id="" v-model="inputVal" placeholder="Please enter the amount" step="0.0000000000000001" @keydown="changeNum">
                                <div class="maxbtn" @click="inputVal = activeItemBalance">Max</div>
                            </div>
                            <!-- <p class="estimatedgas">Estimated Gas Refund: 75%-95%</p> --> 
                            <p v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="tips">Your bLP will be reduced by {{expectedblpnum.toFixed(18)}}.</p>
                            <p v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="tips">The longer unbonding, the more bLP.  </p>
                            <a v-if="activeItem.pools[activeislp == 0 ? 0 : activepoolsIndex].boost != 0" class="learnmore" href="#blpID" @click="farmMessageShow = false;messageIndex = 0">Learn More</a>
                        </div>
                        <!-- <p style="font-size:12px;margin-bottom:20px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> sdfsdfsdfsdf</p> -->
                        <div class="btnbox">
                            <div class="closebtn" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0;inputVal = null">Close</div>
                            <div class="confirmbtn" @click="unstakeComfirm">Confirm <v-LoadingWarp style="margin-left: 5px;" v-if="comfirmLoading" :imgSize="1"></v-LoadingWarp></div>
                        </div>
                    </div>
                    <div class="harvestbox" v-if="messageIndex == 4">
                        <div class="titlebox">
                            <h1>
                                Harvest
                                <i class="el-icon-close" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0"></i>
                            </h1>
                        </div>
                        <div class="infobox">
                            <!-- <p class="available">{{ activeItemBalance }} {{ activeItem.coin.name }} Available</p> -->
                            <div class="iptbox" v-if="this.activeislp == 0 && this.activeItem.pools[0].poolInfo.xRate != 10000 || this.activeislp != 0 && this.activeItem.pools[this.activepoolsIndex].poolInfo.xRate != 10000">
                                <input type="number" name="" id="" :value="this.activeislp == 0 ? this.activeItem.pools[0].PendingRewardsstarNum : this.activeItem.pools[this.activepoolsIndex].PendingRewardsstarNum" disabled>
                                <div class="symbol" >{{tokenSymbol}}</div>
                            </div>
                            <div class="iptbox" v-if="this.activeislp == 0 && this.activeItem.pools[0].poolInfo.xRate != 0 || this.activeislp != 0 && this.activeItem.pools[this.activepoolsIndex].poolInfo.xRate != 0">
                                <input type="number" name="" id="" :value="this.activeislp == 0 ? this.activeItem.pools[0].PendingRewardsxstarNum : this.activeItem.pools[this.activepoolsIndex].PendingRewardsxstarNum" disabled>
                                <div class="symbol" >{{xtokenSymbol}}</div>
                            </div>
                            <div class="iptbox" v-if="this.activeislp == 0 && this.activeItem.pools[0].poollpInfo.isExtra || this.activeislp != 0 && this.activeItem.pools[this.activepoolsIndex].poollpInfo.isExtra">
                                <input type="number" name="" id="" :value="this.activeislp == 0 ? this.activeItem.pools[0].PendingRewardsOneNum : this.activeItem.pools[this.activepoolsIndex].PendingRewardsOneNum" disabled>
                                <div class="symbol" >{{this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpSymbol}}</div>
                            </div>
                            <div class="iptbox" v-if="this.activeislp == 0 && this.activeItem.pools[0].poollpInfo.isTwoExtra || this.activeislp != 0 && this.activeItem.pools[this.activepoolsIndex].poollpInfo.isTwoExtra">
                                <input type="number" name="" id="" :value="this.activeislp == 0 ? this.activeItem.pools[0].PendingRewardsTwoNum : this.activeItem.pools[this.activepoolsIndex].PendingRewardsTwoNum" disabled>
                                <div class="symbol" >{{this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpTwoSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpTwoSymbol}}</div>
                            </div>
                            <div class="iptbox" v-if="this.activeislp == 0 && this.activeItem.pools[0].poollpInfo.isThrExtra || this.activeislp != 0 && this.activeItem.pools[this.activepoolsIndex].poollpInfo.isThrExtra">
                                <input type="number" name="" id="" :value="this.activeislp == 0 ? this.activeItem.pools[0].PendingRewardsThreeNum : this.activeItem.pools[this.activepoolsIndex].PendingRewardsThreeNum" disabled>
                                <div class="symbol" >{{this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpThrSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpThrSymbol}}</div>
                            </div>
                            <!-- <p class="estimatedgas">Estimated Gas Refund: 75%-95%</p> --> 
                        </div>
                        <!-- <p style="font-size:12px;margin-bottom:20px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> sdfsdfsdfsdf</p> -->
                        <div class="btnbox">
                            <div class="closebtn" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0;inputVal = null">Close</div>
                            <div class="confirmbtn" @click="harvestallComfirm">Confirm  <v-LoadingWarp style="margin-left: 5px;" v-if="comfirmLoading" :imgSize="1"></v-LoadingWarp></div>
                        </div>
                    </div>
                    <div class="stakeunstaketipbox" v-if="messageIndex == 10">
                        <div class="titlebox">
                            <h1>
                                Tips
                                <i class="el-icon-close" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0"></i>
                            </h1>
                        </div>
                        <div class="infobox">
                            <p class="available" style="line-height: 25px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> When decrease / increase the deposit, it will harvest rewards as well. Do you confirm?</p>
                        </div>
                        <div class="btnbox">
                            <div class="closebtn" @click="farmMessageShow = false;comfirmLoading = false;messageIndex = 0;inputVal = null">Close</div>
                            <div class="confirmbtn" @click="messageIndex = isbtn == 'plusbtn' ? 2 : 3">Confirm </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    
        <v-Footer></v-Footer>
        
		<message-model ref="messageModel"></message-model>
    </div>
</template>

<script>
import messageModel from '@/components/Message.vue'
import Web3 from 'web3';
import {changeNumber, numberToFixed, getFarmApr, getOtherFarmApr} from '@/utils/function.js'
import {
    judgeNetwork,getAllowance,setApprove,getstarPerBlock,getTokenSymbol,getTokenDecimals,
    getuserBlp,getMultiplier,userTotalPrice,allMultiplier,getXMultiplier,getMinMultiplier,
    getBalanceOf,gettotalAllocPoint,getpoolInfo,getpoolLpInfo,getboost,getPendingStar,getuserInfo,getRedeens,
    getStakingNFTAmount,StakingNFTs,nftUser,nftPendingStar,starMeta,cateURI,nftBoost,
    handdleFarmDeposit,handdleFarmWithdraw,addRedeens,withdrawRedeens,delRedeens,} from '@/utils/space.js'
export default {
    name: "Farm",
	components:{
		messageModel
	},
    data() {
        return {
            userAddress:'',
            tokenSymbol:'',xtokenSymbol:'',
            farmAddress:'',farmLibAddress:'',farmPendingAddress:'',
            yourBlp:0,YourBoostingMultiplier:0,yourBlpRatio:0,
            farmData:{},
            nftList:[],pool0Info:{},poolLp0Inof:{},
            spacePrice:0,nftAPR:0,maxnftAPR:0,
            farmMessageShow:false,messageIndex:0,
            startblock:0,totalAllocPoint:0,
            activeItem:'',activeItemBalance:'',activeislp:false,activepoolsIndex:'',
            inputVal:null,expectedblpnum:0,comfirmLoading:false,isbtn:null,
            nowTime:0,
        }
    },
    computed:{
        // getFullNum(num){
        //     console.log(num)
        //     if(isNaN(num)) return num

        //     var str = '' + num
        //     if(!/e/i.test(str)) return num
        //     console.log((num).toFixed(18).replace(/\.?0+$/, ""))
        //     return (num).toFixed(18).replace(/\.?0+$/, "")
        // },
    },
    watch:{
        inputVal(val){
            if (this.inputVal) {
                this.expectedblpnum = this.$BigNumber(this.activeItem.price).times(this.inputVal).times(this.activeItem.pools[this.activeislp == 0 ? 0 : this.activepoolsIndex].boost/100)
            } else { 
                this.expectedblpnum = 0
            }
        }
    },
    created() { },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardListMainnet
        this.farmAddress = window.contractAddr.farmAddress
        this.farmLibAddress = window.contractAddr.farmLibAddress
        this.farmPendingAddress = window.contractAddr.farmPendingAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.farmData = window.farmData
        this.nowTime = Math.floor( new Date().getTime()/1000 )
        getstarPerBlock(this.farmLibAddress).then((res) => {
            this.startblock = this.$BigNumber(res).div(Math.pow(10,18))
        })
        gettotalAllocPoint(this.farmLibAddress).then((allocPoint) => {
            this.totalAllocPoint = allocPoint
        })
        this.getBlpData()
        var time1 =  setInterval(() => {
            this.tokensList = this.farmData.tokenList
            if(this.farmData.lpPools) {
                for (let i = 0; i < this.farmData.lpPools.length; i++) {
                    if (this.farmData.lpPools[i].price) {
                        if (i == this.farmData.lpPools.length-1) {
                            this.spacePrice = this.farmData.tokenList[1].price
                            this.getSinglePoolInfo()
                            this.getLPPoolInfo()
                            this.getNFTData()
                            clearInterval(time1)
                        }
                    } else {
                        // console.log('farm', this.tokensList[i].price)
                    }
                    
                }
            }
            
            
        }, 500);
    },
    methods: {
        changeNum(e){
            if (e.target._value != '') {
                this.inputVal = e.target._value.replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d).*$/,'$1$2.$3')
            }
        },
        goMint(){
            this.$router.push({path:'/Mint'})
        },
        goxSPACE(){
            this.$router.push({path:'/xSTAR'})
        },
        Details(item){
            this.$router.push({
                path:'/nftDetail',
                query:{
                    tokenid:item.NFTId,
                    type:2
                }
            })
        },
        enableContract(item,index){
            if(!this.userAddress){
                this.$notify({type: 'warning', message: 'Please connect wallet first!'})
                return false
            }
            if(!item.activeSwitch){return false}
            if(item.enableContractLoading){ return false }
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                }
                this.$set(item,'enableContractLoading',true)
                setApprove(item.coin.contract,this.farmAddress,this.userAddress ).then(res => {
                    this.$set(item,'enableContract',true)
                    this.$set(item,'enableContractLoading',false)
                    this.$notify({ type: 'success', message: 'Approve Success' });
                },err => {
                    this.$set(item,'enableContract',false)
                    this.$set(item,'enableContractLoading',false)
                }).catch(function(error){
                    this.$set(item,'enableContract',false)
                    this.$set(item,'enableContractLoading',false)
                });
            },err => {
                //console.log(err)
            }).catch(err => {
                //console.log(err)
            })
        },
        stakebtn(item,islp,poolsIndex){
            this.activeItemBalance = 0
            this.isbtn = 'plusbtn'
            this.inputVal = null
            this.activeItem = item
            this.activeislp = islp
            this.activepoolsIndex = poolsIndex
            this.farmMessageShow = true
            this.activeislp == 0 ? this.activeItem.pools[0].pid : this.activeItem.pools[this.activepoolsIndex].pid
            this.messageIndex = (islp == 0 && this.activeItem.pools[0].stakedNum == 0) || (islp != 0 && this.activeItem.pools[this.activepoolsIndex].stakedNum == 0) ? 2 : 10
            getBalanceOf(this.activeItem.coin.contract,this.userAddress).then(res => {
                // this.activeItemBalance = this.$BigNumber(res).div( Math.pow(10,this.activeItem.coin.decimals))
                this.activeItemBalance = String(this.$BigNumber(res).div(Math.pow(10,this.activeItem.coin.decimals))).indexOf('e-') == -1 ? this.$BigNumber(res).div(Math.pow(10,this.activeItem.coin.decimals)) : this.$BigNumber(res).div(Math.pow(10,this.activeItem.coin.decimals)).toFixed(18)
            })
        },
        stakeComfirm(){
            if(this.comfirmLoading){return false}
            this.comfirmLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.comfirmLoading = false
                    return false
                }
                if(!this.inputVal){
                    this.comfirmLoading = false
                    this.$message({
                        type:'warning',
                        message:'Please enter the amount'
                    })
                    return false;
                }
                if ( parseFloat(this.activeItemBalance) < parseFloat(this.inputVal) ) {
                    this.comfirmLoading = false
                    this.$message({
                        type:'warning',
                        message: 'Insufficient Balance'
                    })
                    return false
                }
                this.handdleChcekFarmDeposit();
            })
        },
        handdleChcekFarmDeposit(){
            var unlockAmount = Web3.utils.toBN( this.$BigNumber(this.inputVal).multipliedBy(this.$BigNumber(Math.pow(10,18))))
            getAllowance(this.activeItem.coin.contract ,this.farmAddress, this.userAddress).then(res => {
                //console.log(res)
                if (Number(res)>Number(unlockAmount)) {
                    this.handdleFarmDepositAfter()
                } else {
                    setApprove(this.activeItem.coin.contract,this.farmAddress,this.userAddress ).then(res => {
                        this.handdleFarmDepositAfter()
                    },error => {
                        this.comfirmLoading = false
                    }).catch(error => {
                        this.comfirmLoading = false
                    })
                }
            })
        },
        handdleFarmDepositAfter(){
            //console.log(this.activeItem)
            var unlockAmount = Web3.utils.toBN( this.$BigNumber(this.inputVal).multipliedBy(this.$BigNumber(Math.pow(10,18))))
            //console.log(this.farmAddress, this.activeislp == 0 ? this.activeItem.pools[0].pid : this.activeItem.pools[this.activepoolsIndex].pid, unlockAmount.toString(), this.userAddress)
            handdleFarmDeposit(this.farmAddress, this.activeislp == 0 ? this.activeItem.pools[0].pid : this.activeItem.pools[this.activepoolsIndex].pid, unlockAmount, this.userAddress ).then(res => {
                var obj = {
                    messageShow:true,
                    starAvailable:this.inputVal,
                    xstarAvailable:0,
                    oneAvailable:0,
                    twoAvailable:0,
                    thrAvailable:0,
                    coin:{
                        star:this.activeItem.coin,
                        xstar:{name:'',contract:''},
                        one:{name:'',contract:''},
                        two:{name:'',contract:''},
                        thr:{name:'',contract:''},
                    },
                    resHash:res,
                }
		        this.$refs.messageModel.open(obj)
                setTimeout(() => {
                    getMultiplier(this.farmPendingAddress,this.userAddress).then((res) => {
                        this.YourBoostingMultiplier = res/100
                    })
                    this.farmData.singlePools.forEach((item,index) => {
                        item.pools.forEach((poolItem,poolIndex) => {
                            getMinMultiplier(this.farmPendingAddress,poolItem.pid,this.userAddress).then(MinMultiplier => {
                                //console.log(poolItem.pid,MinMultiplier,this.YourBoostingMultiplier)
                                if (Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier)) {
                                    poolItem.boostBtn = true
                                } else {
                                    poolItem.boostBtn = false
                                }
                            })
                        })
                    })
                    this.farmData.lpPools.forEach((item,index) => {
                        item.pools.forEach((poolItem,poolIndex) => {
                            getMinMultiplier(this.farmPendingAddress,poolItem.pid,this.userAddress).then(MinMultiplier => {
                                //console.log(poolItem.pid,MinMultiplier,this.YourBoostingMultiplier)
                                if (Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier)) {
                                    poolItem.boostBtn = true
                                } else {
                                    poolItem.boostBtn = false
                                }
                            })
                        })
                    })
                },3000)
                this.getBlpData()
                this.getPool(this.activeItem,this.activeislp == 0 ? this.activeItem.pools[0] : this.activeItem.pools[this.activepoolsIndex],this.activeislp == 0 ? 0 : 1)
                this.getPoollp(this.activeItem,this.activeislp == 0 ? this.activeItem.pools[0] : this.activeItem.pools[this.activepoolsIndex])
                this.getPending(this.activeItem,this.activeislp == 0 ? this.activeItem.pools[0] : this.activeItem.pools[this.activepoolsIndex])
                this.farmMessageShow = false
                this.messageIndex = 0
                this.comfirmLoading = false
            },error => {
                this.comfirmLoading = false
            }).catch(error => {
                this.comfirmLoading = false
            })
        },
        minusbtn(item,islp,poolsIndex){
            //console.log(item)
            this.isbtn = 'minusbtn'
            this.farmMessageShow = true
            this.messageIndex = 10
            this.inputVal = null
            this.activeItem = item
            this.activeislp = islp
            this.activepoolsIndex = poolsIndex
            getuserInfo(this.farmAddress,this.activeislp == 0 ? this.activeItem.pools[0].pid : this.activeItem.pools[this.activepoolsIndex].pid ,this.userAddress).then(userInfo => { 
                this.activeItemBalance = String(this.$BigNumber(userInfo.amount).div(Math.pow(10,this.activeItem.coin.decimals))).indexOf('e-') == -1 ? this.$BigNumber(userInfo.amount).div(Math.pow(10,this.activeItem.coin.decimals)) : this.$BigNumber(userInfo.amount).div(Math.pow(10,this.activeItem.coin.decimals)).toFixed(18)
            })
        },
        unstakeComfirm(){
            if(this.comfirmLoading){return false}
            this.comfirmLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.comfirmLoading = false
                    return false
                }
                if(!this.inputVal){
                    this.comfirmLoading = false
                    this.$message({
                        type:'warning',
                        message:'Please enter the amount'
                    })
                    return false;
                }
                if ( parseFloat(this.activeItemBalance) < parseFloat(this.inputVal) ) {
                    this.comfirmLoading = false
                    this.$message({
                        type:'warning',
                        message: 'Insufficient Balance'
                    })
                    return false
                }
                this.handdleChcekFarmUnstake();
            })
        },
        handdleChcekFarmUnstake(){
            var widthdrawAmount = Web3.utils.toBN( this.$BigNumber(this.inputVal).multipliedBy(this.$BigNumber(Math.pow(10,18))))
            //console.log(widthdrawAmount.toString())
            if (this.activeislp == 0) {
                handdleFarmWithdraw(this.farmAddress,this.activeItem.pools[0].pid,widthdrawAmount, this.userAddress ).then(res => {
                    var obj = {
                        messageShow:true,
                        starAvailable:this.inputVal,
                        xstarAvailable:0,
                        oneAvailable:0,
                        twoAvailable:0,
                        thrAvailable:0,
                        coin:{
                            star:this.activeItem.coin,
                            xstar:{name:'',contract:''},
                            one:{name:'',contract:''},
                            two:{name:'',contract:''},
                            thr:{name:'',contract:''},
                        },
                        resHash:res,
                    }
                    this.$refs.messageModel.open(obj)
                    this.getBlpData()
                    this.getPool(this.activeItem,this.activeItem.pools[0],0)
                    this.getPoollp(this.activeItem,this.activeItem.pools[0])
                    this.getPending(this.activeItem,this.activeItem.pools[0])
                    this.farmMessageShow = false
                    this.messageIndex = 0
                    this.comfirmLoading = false
                }).catch(error => {
                    this.comfirmLoading = false
                })
            } else {
                addRedeens(this.farmAddress,this.activeItem.pools[this.activepoolsIndex].pid,widthdrawAmount, this.userAddress ).then(res => {
                    var obj = {
                        messageShow:true,
                        starAvailable:this.inputVal,
                        xstarAvailable:0,
                        oneAvailable:0,
                        twoAvailable:0,
                        thrAvailable:0,
                        coin:{
                            star:this.activeItem.coin,
                            xstar:{name:'',contract:''},
                            one:{name:'',contract:''},
                            two:{name:'',contract:''},
                            thr:{name:'',contract:''},
                        },
                        resHash:res,
                    }
                    this.$refs.messageModel.open(obj)
                    this.getBlpData()
                    this.getPool(this.activeItem,this.activeItem.pools[this.activepoolsIndex],1)
                    this.getPoollp(this.activeItem,this.activeItem.pools[this.activepoolsIndex])
                    this.getPending(this.activeItem,this.activeItem.pools[this.activepoolsIndex])
                    this.farmMessageShow = false
                    this.messageIndex = 0
                    this.comfirmLoading = false
                }).catch(error => {
                    this.comfirmLoading = false
                })
            }
        },
        harvestAll(item,islp,poolsIndex){
            if((islp == 0 && item.pools[0].stakedNum == 0) || islp != 0 && item.pools[poolsIndex].stakedNum == 0){
                return false
            }
            this.farmMessageShow = true
            this.messageIndex = 4
            this.inputVal = null
            this.activeItem = item
            this.activeislp = islp
            this.activepoolsIndex = poolsIndex
            this.getPending(this.activeItem,this.activeislp == 0 ? this.activeItem.pools[0] : this.activeItem.pools[this.activepoolsIndex])
        },
        harvestallComfirm(){
            if(this.comfirmLoading){return false}
            this.comfirmLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.comfirmLoading = false
                    return false
                }
                //console.log(this.activeItem)
                handdleFarmWithdraw(this.farmAddress,this.activeislp == 0 ? this.activeItem.pools[0].pid : this.activeItem.pools[this.activepoolsIndex].pid,0, this.userAddress ).then(res => {
                    var obj = {
                        messageShow:true,
                        starAvailable:this.activeItem.PendingRewardsstar,
                        xstarAvailable:this.activeItem.PendingRewardsxstar,
                        oneAvailable:this.activeItem.PendingRewardsOne,
                        twoAvailable:this.activeItem.PendingRewardsTwo,
                        thrAvailable:this.activeItem.PendingRewardsThree,
                        coin:{
                            star:this.farmData.spaceToken.SPACE,
                            xstar:this.farmData.spaceToken.xSPACE,
                            one:{
                                name:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpSymbol,
                                contract:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpAddr : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpAddr,
                                decimals:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpDecimals : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpDecimals,
                            },
                            two:{
                                name:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpTwoSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpTwoSymbol,
                                contract:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpTwoAddr : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpTwoAddr,
                                decimals:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpTwoDecimals : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpTwoDecimals,
                            },
                            thr:{
                                name:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpThrSymbol : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpThrSymbol,
                                contract:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpThrAddr : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpThrAddr,
                                decimals:this.activeislp == 0 ? this.activeItem.pools[0].poollpInfo.lpThrDecimals : this.activeItem.pools[this.activepoolsIndex].poollpInfo.lpThrDecimals,
                            }
                        },
                        resHash:res,
                    }
                    //console.log(obj)
                    this.$refs.messageModel.open(obj)

                    this.getPending(this.activeItem,this.activeislp == 0 ? this.activeItem.pools[0] : this.activeItem.pools[this.activepoolsIndex])
                    this.farmMessageShow = false
                    this.messageIndex = 0
                    this.comfirmLoading = false
                    setTimeout(() => {
                        getMultiplier(this.farmPendingAddress,this.userAddress).then((res) => {
                            this.YourBoostingMultiplier = res/100
                        })
                        this.farmData.singlePools.forEach((item,index) => {
                            item.pools.forEach((poolItem,poolIndex) => {
                                getMinMultiplier(this.farmPendingAddress,poolItem.pid,this.userAddress).then(MinMultiplier => {
                                    //console.log(poolItem.pid,MinMultiplier,this.YourBoostingMultiplier)
                                    if (Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier)) {
                                        poolItem.boostBtn = true
                                    } else {
                                        poolItem.boostBtn = false
                                    }
                                })
                            })
                        })
                        this.farmData.lpPools.forEach((item,index) => {
                            item.pools.forEach((poolItem,poolIndex) => {
                                getMinMultiplier(this.farmPendingAddress,poolItem.pid,this.userAddress).then(MinMultiplier => {
                                    //console.log(poolItem.pid,MinMultiplier,this.YourBoostingMultiplier)
                                    if (Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier)) {
                                        poolItem.boostBtn = true
                                    } else {
                                        poolItem.boostBtn = false
                                    }
                                })
                            })
                        })
                    }, 3000);
                }).catch(error => {
                    this.comfirmLoading = false
                })
            })
        },
        poolItemInfo(show,poolIndex){
            if (show == 0) {
                this.farmData.singlePools.forEach((item,index) => {
                    if (poolIndex != index) {
                        this.$set(item,'poolsShow',false)
                    } else {
                        this.$set(item,'poolsShow',!item.poolsShow)
                    }
                });
            } else if(show == 1) {
                this.farmData.lpPools.forEach((item,index) => {
                    if (poolIndex != index) {
                        this.$set(item,'poolsShow',false)
                    } else {
                        this.$set(item,'poolsShow',!item.poolsShow)
                    }
                });
            }
        },
        infodetailsbtn(show,poolDetailIndex,poolDetailIndexIndex){
            if (show == 0) {
                this.farmData.singlePools.forEach((item,index) => {
                    item.pools.forEach((items,indexs) => {
                        if (poolDetailIndex != index) {
                            this.$set(items,'detailShow',false)
                        } else if (poolDetailIndex == index && poolDetailIndexIndex == indexs) {
                            this.$set(items,'detailShow',!items.detailShow)
                        }
                    })
                });
            } else if(show == 1) {
                this.farmData.lpPools.forEach((item,index) => {
                    item.pools.forEach((items,indexs) => {
                        if (poolDetailIndex != index) {
                            this.$set(items,'detailShow',false)
                        } else if (poolDetailIndex == index && poolDetailIndexIndex == indexs) {
                            this.$set(items,'detailShow',!items.detailShow)
                        }
                    })
                });
            }
        },
        unbodingdetailsbtn(show,poolDetailIndex,poolDetailIndexIndex,poolDetailIndexIndexIndex){
            this.farmData.lpPools.forEach((item,index) => {
                item.pools.forEach((items,indexs) => {
                    if (poolDetailIndex != index) {
                        this.$set(items,'unbondingListShow',false)
                    } else if (poolDetailIndex == index && poolDetailIndexIndex == indexs) {
                        this.$set(items,'unbondingListShow',!items.unbondingListShow)
                    }
                })
            });
        },
        redeedClaim(item,poolItem,redeenItem){
            if (poolItem.redeedBtnLoading) { return false }
            poolItem.redeedBtnLoading = true
            redeenItem.redeedBtnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                    return false
                }
                withdrawRedeens(this.farmAddress,poolItem.pid,redeenItem.index,this.userAddress).then(res => {
                    this.getPool(item,poolItem,1)
                    this.getPoollp(item,poolItem)
                    this.getPending(item,poolItem)
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                }).catch(error => {
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                })
            })
        },
        redeedCancel(item,poolItem,redeenItem){
            if (poolItem.redeedBtnLoading) { return false }
            poolItem.redeedBtnLoading = true
            redeenItem.redeedBtnLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                    return false
                }
                delRedeens(this.farmAddress,poolItem.pid,redeenItem.index,this.userAddress).then(res => {
                    this.getPool(item,poolItem,1)
                    this.getPoollp(item,poolItem)
                    this.getPending(item,poolItem)
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                }).catch(error => {
                    poolItem.redeedBtnLoading = false
                    redeenItem.redeedBtnLoading = false
                })
            })
        },
        InitTime(time){ 
            var dd,hh,mm,ss = null;
            if(time<=0){
                return "00D 00h 00min 00s"
            }else{
                dd = Math.floor(time / 60 / 60 / 24);
                hh = Math.floor((time / 60 / 60) % 24);
                mm = Math.floor((time / 60) % 60);
                ss = Math.floor(time % 60);
                if (hh < 10 ){hh = "0" + hh ;}
                if (mm < 10 ){mm = "0" + mm ;}
                if (ss < 10 ){ss = "0" + ss ;}
                if(dd==0&&hh==0&&mm==0){
                    var str = "00D 00h 00min "+ss+"s"
                }
                else if(dd==0&&hh==0){
                    var str = "00D 00h "+mm + "min " +ss+"s"
                }
                else if(dd==0){
                    str =  "00D "+hh+"h "+mm+"min "+ss+"s"
                }else{
                    str = ""+dd+"D "+hh+"h "+mm+"min "+ss+"s";
                }
                return str;
            }
        },
        getPool(item,poolItem,islp){
            if (this.userAddress) {
                getMultiplier(this.farmPendingAddress,this.userAddress).then((res) => {
                    this.YourBoostingMultiplier = res/100
                })
            }
            item.allStakedNum = 0
            item.yourAllStakedNum = 0
            getpoolInfo(this.farmLibAddress,poolItem.pid).then(poolInfo => {
                poolItem.daysUnbonding = poolInfo.bond/60/60/24
                poolInfo.TotalStaked = numberToFixed(this.$BigNumber(poolInfo.lpSupply).div(Math.pow(10,item.coin.decimals)).times(item.price),6)
                poolItem.poolInfo = poolInfo
                var allTokenPrice = ((poolInfo.lpSupply/Math.pow(10,item.coin.decimals)+poolInfo.extraAmount/Math.pow(10,item.coin.decimals))*(item.price))
                poolItem.tokenApr = allTokenPrice == 0 ? 0 : getFarmApr(this.startblock,poolInfo.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)
                if (item.coin.name == 'ZK-ETH') {
                    poolItem.otherTokenApr = getOtherFarmApr(poolItem.pid == 22 ? 0.000651 : poolItem.pid == 23 ? 0.000977 : 0.001406,this.farmData.tokenList[5].price,allTokenPrice)
                } else if (item.coin.name == 'STAR-USDC.e') {
                    poolItem.otherTokenApr = getOtherFarmApr(poolItem.pid == 7 ? 0.000056 : poolItem.pid == 8 ? 0.000073 : 0.000134,this.farmData.tokenList[5].price,allTokenPrice)
                } else if (item.coin.name == 'STAR-ETH') {
                    poolItem.otherTokenApr = getOtherFarmApr(poolItem.pid == 4 ? 0.000112 : poolItem.pid == 5 ? 0.000168 : 0.000468,this.farmData.tokenList[5].price,allTokenPrice)
                } else {
                    poolItem.otherTokenApr = 0
                }
                poolItem.yourtokenApr = (poolItem.tokenApr+poolItem.otherTokenApr)*this.YourBoostingMultiplier
                item.allStakedNum += ((poolInfo.lpSupply/Math.pow(10,item.coin.decimals))*(item.price))
                item.tokenApr = Number(item.tokenApr) > Number(poolItem.tokenApr+poolItem.otherTokenApr) ? Number(item.tokenApr) : Number(poolItem.tokenApr+poolItem.otherTokenApr)
                getboost(this.farmLibAddress,poolItem.pid).then( boost => {
                    poolItem.boost = boost
                    poolItem.tokenAprMax = allTokenPrice == 0 ? 0 : getFarmApr(this.startblock,poolInfo.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)*2.2//(1+(boost/100))
                    if (item.coin.name == 'ZK-ETH') {
                        poolItem.otherTokenAprMax = getOtherFarmApr(poolItem.pid == 22 ? 0.000651 : poolItem.pid == 23 ? 0.000977 : 0.001406,this.farmData.tokenList[5].price,allTokenPrice)*2.2
                    } else if (item.coin.name == 'STAR-USDC.e') {
                        poolItem.otherTokenAprMax = getOtherFarmApr(poolItem.pid == 7 ? 0.000056 : poolItem.pid == 8 ? 0.000073 : 0.000134,this.farmData.tokenList[5].price,allTokenPrice)*2.2
                    } else if (item.coin.name == 'STAR-ETH') {
                        poolItem.otherTokenAprMax = getOtherFarmApr(poolItem.pid == 4 ? 0.000112 : poolItem.pid == 5 ? 0.000168 : 0.000468,this.farmData.tokenList[5].price,allTokenPrice)*2.2
                    } else {
                        poolItem.otherTokenAprMax = 0
                    }
                    item.tokenAprMax = item.tokenAprMax > poolItem.tokenAprMax+poolItem.otherTokenAprMax ? item.tokenAprMax : poolItem.tokenAprMax+poolItem.otherTokenAprMax
                })
                
                if(this.userAddress){
                    this.getRedeensList(item,poolItem)
                    getuserInfo(this.farmAddress,poolItem.pid,this.userAddress).then(userInfo => {    
                        // userInfo.amount = 
                        poolItem.stakedNum = numberToFixed(this.$BigNumber(userInfo.amount).div( Math.pow(10,item.coin.decimals)).times(item.price))
                        //console.log(poolItem.pid,poolItem.stakedNum)
                        item.yourAllStakedNum += Number(this.$BigNumber(userInfo.amount).div( Math.pow(10,item.coin.decimals)).times(item.price).toString())
                        getAllowance(item.coin.contract ,this.farmAddress, this.userAddress).then( allowanRes => {
                            if(allowanRes != 0){
                                item.enableContract = true
                            } else if (allowanRes == 0 && poolItem.stakedNum != 0) {
                                item.enableContract = true
                            } else {
                                item.activeSwitch = true
                            }
                        })
                    }) 
                    getMinMultiplier(this.farmPendingAddress,poolItem.pid,this.userAddress).then(MinMultiplier => {
                        //console.log(poolItem.pid,MinMultiplier,this.YourBoostingMultiplier,Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier))
                        if (Number(MinMultiplier/100) != Number(this.YourBoostingMultiplier)) {
                            poolItem.boostBtn = true
                        } else {
                            poolItem.boostBtn = false
                        }
                    })
                    
                }
            })
        },
        getPoollp(item,poolItem){
            //console.log(item)
            getpoolLpInfo(this.farmLibAddress,poolItem.pid,this.userAddress).then(poollpInfo => {
                this.getlpTokenSymbol(poollpInfo.lpAddr).then(res => {
                    poollpInfo.lpSymbol = res.symbol
                    poollpInfo.lpDecimals = res.decimals
                    item.lpSymbol = res.symbol
                })
                this.getlpTokenSymbol(poollpInfo.lpTwoAddr).then(res => {
                    poollpInfo.lpTwoSymbol = res.symbol
                    poollpInfo.lpTwoDecimals = res.decimals
                    item.lpTwoSymbol = res.symbol
                })
                this.getlpTokenSymbol(poollpInfo.lpThrAddr).then(res => {
                    poollpInfo.lpThrSymbol = res.symbol
                    poollpInfo.lpThrDecimals = res.decimals
                    item.lpThrSymbol = res.symbol
                })
                poolItem.poollpInfo = poollpInfo
            })
        },
        getRedeensList(item,poolItem){
            poolItem.unbondingAll = 0
            poolItem.redeensList =[]
            getRedeens(this.farmAddress,poolItem.pid,this.userAddress).then(redeensList => {
                this.nowTime = Math.floor( new Date().getTime()/1000 )
                for (let i = 0; i < redeensList.length; i++) {
                    var obj = { 
                        amount:redeensList[i].amount/Math.pow(10,item.coin.decimals)*item.price,
                        dividendsAllocation:redeensList[i].dividendsAllocation,
                        endTime:redeensList[i].endTime,
                        endTimeText:this.InitTime(redeensList[i].endTime-this.nowTime),
                        index:i,
                        redeedBtnLoading:false
                    }
                    poolItem.unbondingAll += obj.amount
                    poolItem.redeensList.push(obj)
                }
            })
        },
        getPending(item,poolItem){
            item.PendingRewardsstar = 0
            item.PendingRewardsxstar = 0
            item.PendingRewardsOne = 0
            item.PendingRewardsTwo = 0
            item.PendingRewardsThree = 0
            if (this.userAddress) {
                getPendingStar(this.farmPendingAddress, poolItem.pid, this.userAddress).then(pendingstar => {
                    poolItem.PendingRewardsstar = numberToFixed(this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(0))
                    poolItem.PendingRewardsxstar = this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(1).toFixed(3)
                    poolItem.PendingRewardsOne =  this.$BigNumber(pendingstar._amountLpPendingStar).div(Math.pow(10,item.coin.decimals)).toFixed(3)
                    poolItem.PendingRewardsTwo = numberToFixed(this.$BigNumber(pendingstar._amountLpTwoPendingStar).div(Math.pow(10,item.coin.decimals)))
                    poolItem.PendingRewardsThree = numberToFixed(this.$BigNumber(pendingstar._amountLpThrPendingStar).div(Math.pow(10,item.coin.decimals)))
                    poolItem.PendingRewardsstarNum = this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(0)
                    poolItem.PendingRewardsxstarNum = String(this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(1)).indexOf('e-') == -1 ? this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(1) : this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(1).toFixed(18)
                    poolItem.PendingRewardsOneNum = String(this.$BigNumber(pendingstar._amountLpPendingStar).div(Math.pow(10,item.coin.decimals))).indexOf('e-') == -1 ? this.$BigNumber(pendingstar._amountLpPendingStar).div(Math.pow(10,item.coin.decimals)) : this.$BigNumber(pendingstar._amountLpPendingStar).div(Math.pow(10,item.coin.decimals)).toFixed(18)
                    poolItem.PendingRewardsTwoNum = this.$BigNumber(pendingstar._amountLpTwoPendingStar).div(Math.pow(10,item.coin.decimals))
                    poolItem.PendingRewardsThreeNum = this.$BigNumber(pendingstar._amountLpThrPendingStar).div(Math.pow(10,item.coin.decimals))
                    item.PendingRewardsstar += Number(this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(0).toString())
                    item.PendingRewardsxstar += Number(this.$BigNumber(pendingstar._amountpendingStar).div(Math.pow(10,item.coin.decimals)).times(1).toString())
                    item.PendingRewardsOne += Number(this.$BigNumber(pendingstar._amountLpPendingStar).div(Math.pow(10,item.coin.decimals)).toString())
                    item.PendingRewardsTwo += Number(this.$BigNumber(pendingstar._amountLpTwoPendingStar).div(Math.pow(10,item.coin.decimals)).toString())
                    item.PendingRewardsThree += Number(this.$BigNumber(pendingstar._amountLpThrPendingStar).div(Math.pow(10,item.coin.decimals)).toString())
                    this.$forceUpdate()
                })
            }
        },
        getlpTokenSymbol(addr){
            return new Promise((resolve, reject) => {
                if (addr == "0x0000000000000000000000000000000000000000") {
                    resolve({symbol:localStorage.getItem('nativeCurrencySymbol'),decimals:18})
                } else {
                    getTokenSymbol(addr).then(symbol => {
                        getTokenDecimals(addr).then(decimals => {
                            resolve({symbol,decimals})
                        })
                    }).catch(error => {
                        //console.log(addr,error)
                    })
                }
            })
        },
        
        getSinglePoolInfo(){
            this.farmData.singlePools.forEach((item,index) => {
                item.pools.forEach((poolItem,poolIndex) => {
                    this.getPool(item,poolItem,0)
                    this.getPoollp(item,poolItem)
                    this.getPending(item,poolItem)
                })
            })
        },
        getLPPoolInfo(){
            this.farmData.lpPools.forEach((item,index) => {
                if(item.isShow){
                    item.lpFee = 0.00

                    this.$axios.get('https://api.dexscreener.com/latest/dex/pairs/zksync/'+item.coin.contract).then(res => {
                        var liquidity = res.pairs[0].liquidity.usd
                        var volume = res.pairs[0].volume.h24
                        var lpFee = volume*0.3/100*365/liquidity*100*0.5
                        // if(farmLPItem.coin.contract == "0x4B747B1f9DBAE8C55B007817553B49aCb360812F" ){
                        //     farmLPItem.feeAPR = 500
                        // }else {
                            item.lpFee = lpFee
                        // }
                        item.liquidity = res.pairs[0].liquidity.usd 
                    })
                    item.pools.forEach((poolItem,poolIndex) => {
                        this.getPool(item,poolItem,1)
                        this.getPoollp(item,poolItem)
                        this.getPending(item,poolItem)
                    })
                }
                
            })
        },
        getNFTData(){
            getpoolInfo(this.farmLibAddress,0,this.userAddress).then(pool0Info => {
                pool0Info.xSPACERate = pool0Info.xRate/10000
                pool0Info.SPACERate = 1 - pool0Info.xRate/10000
                this.pool0Info = pool0Info
                nftBoost(this.farmPendingAddress).then(nftboost => {
                    var allTokenPrice = ((pool0Info.lpSupply/Math.pow(10,18)+pool0Info.extraAmount/Math.pow(10,18))*(this.spacePrice))
                    //console.log(this.startblock.toString(),pool0Info.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)
                    this.nftAPR = allTokenPrice == 0 ? 0 : getFarmApr(this.startblock,pool0Info.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)*(nftboost/100)
                    this.maxnftAPR = this.nftAPR*2.2//allTokenPrice == 0 ? 0 : getFarmApr(this.startblock,pool0Info.allocPoint/this.totalAllocPoint,this.spacePrice,allTokenPrice)*(nftboost/100)*2.2
                })
                
            })
            getpoolLpInfo(this.farmLibAddress,0,this.userAddress).then(poolLp0Inof => {
                this.getlpTokenSymbol(poolLp0Inof.lpAddr).then(res => {
                    poolLp0Inof.lpOneSymbol = res.symbol
                })
                this.getlpTokenSymbol(poolLp0Inof.lpTwoAddr).then(res => {
                    poolLp0Inof.lpTwoSymbol = res.symbol
                })
                this.getlpTokenSymbol(poolLp0Inof.lpThrAddr).then(res => {
                    poolLp0Inof.lpThrSymbol = res.symbol
                })
                this.poolLp0Inof = poolLp0Inof
            })
            this.nftList = []
            getStakingNFTAmount(this.farmAddress).then(stakingNum => {
                for (let i = stakingNum; i > stakingNum-4 ; i--) {
                    if (i-1 >= 0) {
                        StakingNFTs(this.farmAddress,i-1).then(NFTId => {
                            nftUser(this.farmAddress,NFTId,this.userAddress).then(owner => {
                                nftPendingStar(this.farmPendingAddress,NFTId,owner).then(peddingStar => {
                                    starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                                        cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                            var obj = {
                                                nftUrl,
                                                cateId:nftInfo.cateId,
                                                NFTId:Number(NFTId),
                                                name:'',
                                                multiple:Number(nftInfo.multiple/100).toFixed(2),
                                                assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                                stakingRewardSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.SPACERate ),
                                                stakingRewardxSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.xSPACERate ),
                                                stakingRewardLpOne:Number( peddingStar._amountLpPendingStar/1e18 ),
                                                stakingRewardLpTwo:Number( peddingStar._amountLpTwoPendingStar/1e18 ),
                                                stakingRewardLpThr:Number( peddingStar._amountLpThrPendingStar/1e18 ),
                                            }
                                            this.nftInfoList.forEach((item,index) => {
                                                if (item.cid == nftUrl.split('/')[3]) {
                                                    obj.name = item.name
                                                }
                                            })
                                            this.nftList.push(obj)
                                            let arr0 = this.nftList
                                            this.dataSort(arr0)
                                        })
                                    })
                                })
                            })
                        })
                    } 
                }
            })
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (a.NFTId > b.NFTId) {
                    return -1
                } else if (a.NFTId < b.NFTId) {
                    return 1
                }
            })
        },
       
        getBlpData(){
            if (this.userAddress) {
                getuserBlp(this.farmPendingAddress,this.userAddress).then((getuserBlpres) => {
                    this.yourBlp = getuserBlpres/1e6
                    getXMultiplier(this.farmPendingAddress,this.userAddress).then((getXMultiplierres) => {
                        this.nftxspaceMultiplier = getXMultiplierres/100
                        userTotalPrice(this.farmPendingAddress,this.userAddress).then((userTotalPriceres) => {
                            allMultiplier(this.farmPendingAddress,this.userAddress).then((allMultiplierres) => {
                                var num = this.yourBlp / (userTotalPriceres/1e6) * (allMultiplierres/100) * this.nftxspaceMultiplier
                                this.yourBlpRatio = isNaN(num) ? 0 : numberToFixed(num,4)
                            })
                        })
                        
                    })
                })
                getMultiplier(this.farmPendingAddress,this.userAddress).then((res) => {
                    this.YourBoostingMultiplier = res/100
                })
                
                
            }
        },
        goAddLiquiditySTAR(){
            window.open('https://swap-zksync.spacefi.io/#/add/0x838A66F841DD5148475a8918db0732c239499a03/undefined','_self')
        }
        
    }
}
</script>